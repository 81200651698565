import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Container, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import LogoFull from "../assets/icons/logo";
import "./mobile-first-layout.scss"; // Assuming you have a SCSS file for additional styles
import Intercom from "@intercom/messenger-js-sdk";

const MobileFirstLayout = ({ children, title, fixedChild }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "user_token",
    "server_env",
  ]);
  const navigate = useNavigate();
  return (
    <Box className="mobile-first-layout">
      <Box
        sx={{
          position: "absolute",
          top: "2vh",
          left: "2vw",
          display: "flex",
        }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            backgroundColor: "white",
            padding: "6px 6px 6px 6px",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
          onClick={() => {
            navigate(`/account`);
          }}
        >
          <HomeOutlinedIcon sx={{ color: "#1976d2", fontSize: 18 }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "2vh",
          right: "2vw",
          display: "flex",
        }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            backgroundColor: "white",
            padding: "6px 6px 6px 6px",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
          onClick={() => {
            removeCookie("user_token", { path: "/" });
            removeCookie("server_env", { path: "/" });
            Intercom("shutdown");
            navigate(`/login`);
          }}
        >
          <LogoutIcon sx={{ color: "#1976d2", fontSize: 18 }} />
        </IconButton>
      </Box>
      <div className="logo">
        <LogoFull />
      </div>
      {/* <div className="ripple-container">
        <div className="ripple-background">
          <div className="circle xxlarge shade1"></div>
          <div className="circle xlarge shade2"></div>
          <div className="circle large shade3"></div>
        </div>
      </div> */}
      <Container maxWidth="sm" className="container" sx={{ p: 0 }}>
        <Paper className="paper">
          <h1 className="layout-title">{title}</h1>
          {children}
        </Paper>
        {fixedChild != null ? (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <Container maxWidth="sm" className="container" sx={{ p: 0 }}>
              {fixedChild}
            </Container>
          </Box>
        ) : (
          <></>
        )}
      </Container>
    </Box>
  );
};

export default MobileFirstLayout;
