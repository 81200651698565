import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "awesome-snackbar";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
  cancelPlan,
  getPaymentPlanUrl,
} from "../../../../api/subscription-api";
import CustomAlert from "../../../../components/alert/alert";
import RoundedDialogActionButton from "../../../../components/dialog-action-button";
import "./plan-selection.scss";

const PlanSelectionFooter = ({ plans, initialSelectedPlan }) => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(initialSelectedPlan);
  const [getLinkApiLoading, setGetLinkApiLoading] = useState(false);
  const [cookies, setCookie] = useCookies([
    "user_token",
    "payemt_link_id",
    "server_env",
  ]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setSelectedPlan(initialSelectedPlan);
  }, [initialSelectedPlan]);

  const handleGetPaymentLink = async () => {
    if (selectedPlan.isActive) {
      new Snackbar("This plan is already active try a different plan", {
        position: "top-center",
        timeout: 2000,
        style: {
          container: [["background-color", "	#2F80ED"]],
        },
      });
      return;
    }
    setGetLinkApiLoading(true);
    try {
      const response = await getPaymentPlanUrl(
        cookies.user_token,
        selectedPlan.data.id
      );
      setCookie("payemt_link_id", response.data.linkId, {
        expires: new Date(Date.now() + 30 * 60000), // 30 minutes
      });
      setGetLinkApiLoading(false);
      window.open(response.data.link, "_self");
    } catch (err) {
      setGetLinkApiLoading(false);
      new Snackbar("Error generating payment link. Try Again!", {
        position: "top-center",
        timeout: 2000,
        style: {
          container: [["background-color", "#E53935"]],
        },
      });
    }
  };

  const handleCancelPlan = async () => {
    setAlertOpen(true);
  };

  const confirmCancelPlan = async () => {
    setAlertOpen(false);
    try {
      await cancelPlan(cookies.user_token);
      new Snackbar("Subscription cancelled successfully", {
        position: "top-center",
        timeout: 600,
        style: {
          container: [["background-color", "#5cb85c"]],
        },
      });
      navigate("/account");
    } catch (err) {
      new Snackbar(`Error cancelling subscription. Try Again! ${err.message}`, {
        position: "top-center",
        timeout: 600,
        style: {
          container: [["background-color", "#E53935"]],
        },
      });
    }
  };
  console.log(selectedPlan);
  return (
    <Box
      className="plan-duration-selection"
      sx={{
        width: "100%",
        p: 2,
        pt:
          selectedPlan.data.baseUnits == 365 &&
          selectedPlan.data.couponDetails?.discountType === "unit"
            ? 1
            : 3,
      }}
    >
      {selectedPlan.data.baseUnits == 365 &&
      selectedPlan.data.couponDetails?.discountType === "unit" ? (
        <div className="mb-4 p-2 ">
          <p className="text-center">{selectedPlan.ui.discountCardText}</p>
        </div>
      ) : (
        <></>
      )}

      <ListOfPlansToSelect
        plans={plans}
        selectedPlan={selectedPlan}
        onPlanSelect={setSelectedPlan}
      />

      <Button
        fullWidth
        onClick={() => handleGetPaymentLink()}
        sx={{ mb: 1 }}
        className="primary-button"
        disabled={getLinkApiLoading}
      >
        {getLinkApiLoading ? (
          <CircularProgress size={24} sx={{ color: "blue" }} />
        ) : (
          selectedPlan.ui.buttonLabel
        )}
      </Button>
      <Button fullWidth onClick={handleCancelPlan} className="secondary-button">
        Cancel subscription
      </Button>
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        <a href="https://superr-legal.s3.ap-south-1.amazonaws.com/EULA.html">
          Terms (EULA)
        </a>
        <a href="https://app.superr.app/terms-and-conditions">
          Terms & Conditions
        </a>
        <a href="https://superr-legal.s3.ap-south-1.amazonaws.com/privacy-policy.html">
          Privacy Policy
        </a>
      </Typography>
      <CustomAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        title="Confirm Cancellation"
        subtitle="Are you sure you want to cancel your subscription?"
        imageSrc="/images/delete-image.png" // Replace with actual path
        actions={[
          <RoundedDialogActionButton
            label="Cancel"
            onClick={() => setAlertOpen(false)}
            color="default"
            icon={null}
          />,
          <RoundedDialogActionButton
            label="Yes, Cancel Subscription"
            onClick={() => confirmCancelPlan()}
            color="error"
            icon={null}
            style={{ fontSize: "12px !important" }}
          />,
        ]}
        primaryColor="#E53935"
      />
    </Box>
  );
};

const ListOfPlansToSelect = ({ plans, selectedPlan, onPlanSelect }) => {
  console.log(plans);
  return (
    <div className="plan-options">
      {plans.map((plan) => (
        <Button
          key={plan.data.id}
          fullWidth
          onClick={() => onPlanSelect(plan)}
          style={{
            border: `1px solid ${
              plan.isActive ? "white" : "rgba(255, 0, 0, 0.0)"
            }`,
          }}
          className={`plan-option-button ${
            plan.data.id === selectedPlan.data.id && plan.isActive === false
              ? "active"
              : ""
          }`}
        >
          {plan.ui.discount && (
            <div className="discount-badge">
              <span>{plan.ui.discount}</span>
            </div>
          )}
          <div className="plan-option-button-container">
            <div className="d-flex align-items-center">
              <Checkbox
                checked={plan.data.id === selectedPlan.data.id}
                size="small"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
              <div>{plan.ui.header}</div>
            </div>

            <p className="monthly-price">{plan.ui.showPrice}</p>
            <div>
              <span className="real-price">{plan.ui.paymentPrice}</span>
            </div>
          </div>
        </Button>
      ))}
    </div>
  );
};

export default PlanSelectionFooter;
