import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import "../plan-page.scss";

const PlanSelectionTabs = ({ segments, currentSegment, onChange }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className="plan-selection-tab">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentSegment}
          onChange={handleChange}
          aria-label="segmented controller"
          variant="fullWidth"
        >
          {segments.map((segment, index) => (
            <Tab key={index} label={segment} />
          ))}
        </Tabs>
      </Box>
    </div>
  );
};

export default PlanSelectionTabs;
