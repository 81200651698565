import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./transaction-history.scss";

const TransactionHistoryCard = ({ payment }) => (
  <div className="transaction-history__card">
    <div className="transaction-history__header">
      <div className="icon">
        {payment.subscriptionPlatform === "play_store" ? (
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Google_Play_2016_icon.svg/1200px-Google_Play_2016_icon.svg.png"
            alt="Play Store"
          />
        ) : payment.subscriptionPlatform === "app_store" ? (
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/App_Store_%28iOS%29.svg/512px-App_Store_%28iOS%29.svg.png"
            alt="App Store"
          />
        ) : (
          <img src="/images/logo.png" alt="Superr" />
        )}
      </div>
      <div className="title">Subscription payment</div>
      <div className="amount">{payment.amount}</div>
    </div>
    <div className="transaction-history__date">
      {new Date(payment.date).toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })}
    </div>
    <div className="transaction-history__details">
      <div className="label">Transaction id:</div>
      <div className="value">{payment.paymentId}</div>
    </div>
  </div>
);

const TransactionHistory = ({ history }) => {
  const [isOpen, setIsOpen] = useState(
    history === null || history.length === 0
  );

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (history === null || history.length === 0) {
    return (
      <div className="transaction-history__no-history">
        <Typography variant="h6">No Billing History</Typography>
        <Typography variant="body2">
          We’ll send you a notification <strong>2 days</strong> before your
          trial ends
        </Typography>
      </div>
    );
  }

  //console.log(history);
  return (
    <div className="transaction-history">
      <Accordion
        className="transaction-history__accordion"
        expanded={isOpen}
        onChange={toggleOpen}
      >
        <AccordionSummary expandIcon={isOpen ? <ExpandLess /> : <ExpandMore />}>
          <Typography variant="h6">Billing History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {history.map((item, index) => (
            <TransactionHistoryCard key={index} payment={item} />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TransactionHistory;
