import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import "./plan-feature-table.scss";

function PlanFeatureTable({ tiers, features, selectedTier }) {
  return (
    <TableContainer>
      <Table
        className="plan-feature-table"
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell className="header feature">Features</TableCell>
            {tiers.map((tier) => (
              <TableCell
                key={tier.id}
                className={`header  ${
                  tier.id === selectedTier ? "selected-tier" : "unselected-tier"
                } `}
              >
                {tier.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.id}>
              <TableCell className="feature-name">
                {feature.displayName}
              </TableCell>
              {tiers.map((tier) => (
                <TableCell key={tier.id} align="center">
                  {feature.availableTiers.includes(tier.id) ? (
                    <CheckRoundedIcon
                      className={` ${
                        tier.id === selectedTier
                          ? "selected-tier-svg"
                          : "unselected-tier-svg"
                      } `}
                    />
                  ) : (
                    <HorizontalRuleRoundedIcon
                      className={` horizontalline ${
                        tier.id === selectedTier
                          ? "selected-tier-svg"
                          : "unselected-tier-svg"
                      } `}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PlanFeatureTable;
