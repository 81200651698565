import axios from "axios";

/**
 * Fetches active subscription details from the server, including the access token from cookies.
 * @returns {Promise} Promise object representing the API response.
 */
export const getSubscriptionDetailsApi = async (token) => {
  try {
    const response = await axios.get("/api/subscription/v1/active/details", {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error.toString());
    throw error;
  }
};

/**
 * Fetches subscription plans from the server.
 * @returns {Promise} Promise object representing the API response.
 */
export const fetchSubscriptionPlansApi = async (token) => {
  try {
    const response = await axios.post(
      "/api/subscription/v1/subscription/plan/list",
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch subscription plans API error:", error);
    throw error;
  }
};

/**
 * Fetches subscription plan payem link.
 * @returns {Promise} Promise object representing the API response.
 */
export const fetchPlanPaymentLinkApi = async (token) => {
  try {
    const response = await axios.post(
      "/api/subscription/v1/subscription/plan/list",
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch subscription plans API error:", error);
    throw error;
  }
};

/**
 * Fetches subscription plan payem link.
 * @returns {Promise} Promise object representing the API response.
 */
export const getPaymentPlanUrl = async (token, id) => {
  try {
    const response = await axios.get(`/api/subscription/v2/web/link/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch subscription plans API error:", error);
    throw error;
  }
};

/**
 * canacel plan
 * @returns {Promise} Promise object representing the API response.
 */
export const cancelPlan = async (token) => {
  try {
    const response = await axios.post(
      "/api/subscription/v1/web/cancel",
      {},
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch subscription plans API error:", error);
    throw error;
  }
};

/**
 * update payment status
 * @param {string} token - The user token for authorization.
 * @param {string} linkId - The link ID for the payment.
 * @returns {Promise} Promise object representing the API response.
 */
export const updatePaymentStatus = async (token, linkId) => {
  try {
    const response = await axios.put(
      "/api/payment/v1/payment/link/status/web",
      { linkId },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Update payment status API error:", error);
    throw error;
  }
};
