import { Widget } from "@typeform/embed-react";
import { memo } from "react";

// function Card({ count, updateCount }) {
function DeleteForm({ userDetails, onReady }) {
  return (
    <Widget
      id={`r8djo43d#email=${userDetails.email}`}
      style={{ width: "100%", height: "55vh" }}
      className="my-form"
      onReady={onReady}
    />
  );
}

export default memo(DeleteForm);
