import { Refresh } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// import "./SubscriptionDetailsScreen.scss"; // Assuming CSS styles are in place
import { getSubscriptionDetailsApi } from "../../../api/subscription-api";
import CurrentSubscriptionCard from "./card/subscription-card";
import TransactionHistory from "./card/transaction-history";
import MobileFirstLayout from "../../../layout/mobile-first-layout";

const SubscriptionDetailsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [cookies, setCookie] = useCookies(["user_token"]);

  const fetchSubscriptionDetails = async () => {
    setLoading(true);
    setError(false);
    try {
      const token = cookies.user_token;
      const response = await getSubscriptionDetailsApi(token);

      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  return (
    <div className="subscription-details">
      <MobileFirstLayout title={"Subscription Management"}>
        {loading ? (
          <Container className="spinner-container d-flex pt-5 justify-content-center align-items-center w-100">
            <CircularProgress thickness={4} />
          </Container>
        ) : error ? (
          <Container className="error-container">
            <Typography variant="h6">
              Failed to load subscription details
            </Typography>
            <Button onClick={fetchSubscriptionDetails} startIcon={<Refresh />}>
              Retry
            </Button>
          </Container>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CurrentSubscriptionCard
                freeTrialData={data?.freeTrial}
                paidPlanData={data?.activeSubscription}
                primaryAction={() => {
                  /* Add your primary action here */
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TransactionHistory history={data?.paymentHistory} />
            </Grid>
          </Grid>
        )}
      </MobileFirstLayout>
    </div>
  );
};

export default SubscriptionDetailsScreen;
