import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  InputLabel,
  Typography,
  FormHelperText,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { useState } from "react";
import "../payment-login.scss";
import CustomAlert from "../../../components/alert/alert";
import RoundedDialogActionButton from "../../../components/dialog-action-button";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { logInApi } from "../../../api/authentication-api";
import Snackbar from "awesome-snackbar";
import { useCookies } from "react-cookie";
import Intercom from "@intercom/messenger-js-sdk";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user_token"]);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);
  const [forgotPassDialog, setForgotPassDialog] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [telErr, setTelErr] = useState(null);
  const [password, setPassword] = useState("");
  const [passErr, setPassErr] = useState(null);
  const [logingIn, setLoggingIn] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleLogin = async () => {
    // Start login process
    setLoggingIn(true);

    // Validate input
    if (!phoneNumber) {
      setTelErr("Phone Number is Required");
      setLoggingIn(false);
      return;
    }
    if (!phoneNumberValid) {
      setTelErr("Phone Number is Invalid");
      setLoggingIn(false);
      return;
    }
    if (!password) {
      setPassErr("Password is Required");
      setLoggingIn(false);
      return;
    }

    try {
      const res = await logInApi({ phoneNumber, password });
      console.log(res.data.data);
      Intercom("boot", {
        app_id: "dasnn3e1",
        user_id: res.data.data.guardianId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: res.data.data.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: res.data.data.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      });
      setCookie("user_token", res.data.data.accessToken, {
        path: "/", // Ensure the path is set if needed
        expires: new Date(Date.now() + 86400e3), // 24 hours from now
      });
    } catch (error) {
      handleLoginError(error);
      setLoggingIn(false);
    } finally {
      setLoggingIn(false); // Ensures this is called only once
      navigate("/account");
    }
  };

  const handleLoginError = (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        const errorMessage = error.response.data.errors[0].message;
        new Snackbar(errorMessage, {
          position: "top-center",
          timeout: 600,
          style: {
            container: [["background-color", "#E53935"]],
          },
        });
      } else {
        setPassErr(error.response.data.msg);
      }
    } else {
      console.error("Unknown error during login:", error);
    }
  };

  return (
    <>
      <CustomAlert
        open={signUpDialog}
        setOpen={setSignUpDialog}
        title={"Start Your Journey"}
        subtitle={"Sign up through the Superr app to get started."}
        imageSrc={"/images/sign_up.png"}
        actions={[
          <RoundedDialogActionButton
            label={"Cancel"}
            onClick={() => setSignUpDialog(false)}
            color="default"
            icon={null}
          />,
          <RoundedDialogActionButton
            label={"Use app"}
            onClick={() => {
              setSignUpDialog(false);
              window.open(
                "https://superrapp.page.link/parents-app",
                "_blank",
                "noreferrer"
              );
            }}
            color="primary"
            icon={<OpenInNewRoundedIcon />}
          />,
        ]}
        primaryColor={"#17437D"}
      />
      <CustomAlert
        open={forgotPassDialog}
        setOpen={setForgotPassDialog}
        title={"Password Reset Instructions"}
        subtitle={
          "To reset your password, please open the app and follow the steps shown in the guide above."
        }
        imageSrc={"/images/forget-password.gif"}
        actions={[
          <RoundedDialogActionButton
            label={"Cancel"}
            onClick={() => setForgotPassDialog(false)}
            color="default"
            icon={null}
          />,
          <RoundedDialogActionButton
            label={"Use app"}
            onClick={() => {
              setForgotPassDialog(false);
              window.open(
                "https://superrapp.page.link/parents-app",
                "_blank",
                "noreferrer"
              );
            }}
            color="primary"
            icon={<OpenInNewRoundedIcon />}
          />,
        ]}
        primaryColor={"#17437D"}
      />
      <Box
        sx={{
          p: { xs: 0, md: 3 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          width: "100%",
          maxWidth: "500px",
        }}
        className="payment_login"
      >
        <div>
          <Typography variant="h5" className="header">
            Welcome back
          </Typography>
          <Typography variant="h6" className="subtitle">
            Log in to access your account and manage your services.
          </Typography>
        </div>
        <div style={{ width: "100%" }}>
          <InputLabel sx={{ color: "#0C1421", mb: 1 }}>
            Mobile Number
          </InputLabel>
          <MuiTelInput
            value={phoneNumber}
            onChange={(val, info) => {
              setTelErr(null);
              const valid = matchIsValidTel(val, {
                onlyCountryies: [], // optional,
                excludedCountryies: [], // optional
                continents: [], // optional
              });
              setPhoneNumberValid(valid);
              setPhoneNumber(info?.numberValue);
            }}
            defaultCountry="IN"
            variant="outlined"
            type="tel"
            error={telErr != null}
            helperText={telErr}
          />
        </div>
        <div style={{ width: "100%" }}>
          <InputLabel sx={{ color: "#0C1421", mb: 1 }}>Password</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(event) => {
              setPassErr(null);
              setPassword(event.target.value);
            }}
            value={password}
            error={passErr != null}
          />
          {passErr != null && (
            <FormHelperText error id="accountId-error">
              {passErr}
            </FormHelperText>
          )}
          <div className="pass_button_contaier">
            <Button
              color="primary"
              className="pass_button"
              onClick={() => setForgotPassDialog(true)}
            >
              Forgot password?
            </Button>
          </div>
        </div>
        <LoadingButton
          variant="contained"
          color="primary"
          className="login_button"
          onClick={() => handleLogin()}
          loading={logingIn}
        >
          <span>Log In Now</span>
        </LoadingButton>
        <Typography variant="body2">
          Don't have an account?{" "}
          <a
            href="#signup"
            className="signup"
            onClick={() => setSignUpDialog(true)}
          >
            Sign up
          </a>
        </Typography>
      </Box>
    </>
  );
}

export default LoginForm;
