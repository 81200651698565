import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./tier-feature-accordion.scss";

function TierFeatureAccordion({ tier }) {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="feature-accordion">
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        className={` ${expanded ? "expanded" : "collapsed"}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-summary d-flex align-items-center"
        >
          <Chip label={tier.displayName} className="accordion-chip" />
          <p className="accordion-title">
            {expanded ? "Hide all benefits" : "See all benefits"}
          </p>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <ul className="feature-list">
            {tier.features.map((feature) => (
              <li key={feature.id} className="feature-item d-flex">
                <p className="text-start">
                  <span variant="body1" component="" className="feature-name">
                    {feature.displayName} -{" "}
                  </span>
                  <span
                    variant="body2"
                    component="p"
                    className="feature-description"
                  >
                    {feature.description || ""}
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default TierFeatureAccordion;
