import axios from "axios";

/**
 * Signs in a user with an email and password.
 * Optionally, a phone number can be used instead of an email.
 * @param {Object} params - Parameters for login
 * @param {string} [params.phoneNumber] - The user's phone number
 * @param {string} params.password - The user's password
 * @returns {Promise} Axios response promise
 */
export const logInApi = async ({ phoneNumber, password }) => {
  const data = {
    phone: phoneNumber?.length ? phoneNumber : null,
    password: password,
  };

  try {
    const response = await axios.post("/api/auth/v1/login", data);
    return response;
  } catch (error) {
    console.error("Login API error:", error);
    throw error;
  }
};

/**
 * Fetches an auth token from the subscription link.
 * @param {string} link - The subscription link
 * @returns {Promise} Axios response promise containing the token
 */
export const getAuthTokenApi = async (link) => {
  const data = {
    link: link,
  };

  try {
    const response = await axios.post(
      "/api/subscription/v1/subscription/auth",
      data
    );
    return response;
  } catch (error) {
    console.error("Get Auth Token API error:", error);
    throw error;
  }
};
