import "./App.css";

import Intercom from "@intercom/messenger-js-sdk";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Authentication from "./pages/authentication/authentication";
import DeleteAccount from "./pages/delete-account";
import EmailVerified from "./pages/email-verified";
import PaymentCompleted from "./pages/payment/PaymentStatusUpdate";
import PLanDetailsPage from "./pages/payment/plans/plans-page";
import SubscriptionDetailsScreen from "./pages/payment/subscription/subscription-management";
import ResetPassword from "./pages/reset-password";
import RedirectPage from "./redirect";

function App() {
  const [cookies, setCookie] = useCookies(["server_env"]);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let env = params.get("env");

  const getEnvironmentUrl = (environment) => {
    switch (environment) {
      case "ALPHA":
        return "alpha.superr.app";
      case "TEST":
        return "test.superr.app";
      default:
        return "prod.superr.app";
      // return "test.superr.app"; // TODO: Comment this out before deploying
    }
  };
  const environmentUrl = getEnvironmentUrl(env);

  if (env !== cookies.server_env) {
    axios.defaults.baseURL = `https://${environmentUrl}`;
  }

  Intercom({
    app_id: "dasnn3e1",
  });

  return (
    <>
      <Routes>
        <Route path="/*" element={<RedirectPage />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/verify-email" element={<EmailVerified />} />
        <Route exact path="/delete-account" element={<DeleteAccount />} />
        <Route
          exact
          path="/login"
          element={
            <AuthRoute>
              <Authentication />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/payments"
          element={
            <AuthRoute>
              <Authentication />
            </AuthRoute>
          }
        />
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <SubscriptionDetailsScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute>
              <PLanDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment-complete"
          element={
            <PrivateRoute>
              <PaymentCompleted />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;

function AuthRoute({ children }) {
  const [cookies] = useCookies(["user_token"]);
  const isAuthenticated = Boolean(cookies.user_token);
  const location = useLocation();

  return isAuthenticated ? (
    <Navigate to="/account" state={{ from: location }} replace />
  ) : (
    children
  );
}

function PrivateRoute({ children }) {
  const [cookies] = useCookies(["user_token"]);
  const isAuthenticated = Boolean(cookies.user_token);
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
