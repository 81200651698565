import axios from "axios";
import React, { useEffect, useState } from "react";
import "./reset-password.scss";

import {
  Cancel,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "awesome-snackbar";
import Lottie from "lottie-react";
import NotFound from "../assets/lottie/404.json";
import PassSuccess from "../assets/lottie/pass-success.json";
import LoadingSpinner from "../components/loading";
import DualLayout from "../layout/dual-layout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function ResetPassword() {
  const [newPass, setNewPass] = useState({
    pass: "",
    confirmPass: "",
  });
  const [showPass, setShowPass] = useState({
    pass: false,
    confirmPass: false,
  });

  const [passErrors, setPassErrors] = useState({
    length: null,
    characters: null,
    isSame: false,
    passRequired: false,
    confirmRequired: false,
  });

  const [fieldError, setFieldError] = useState({
    pass: false,
    confirmPass: false,
  });

  const [apiStatusSuccess, setApiStatusSuccess] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const [codeQuery, setCodeQuery] = useState("loading");
  const [alreadyCalledCheck, setAlreadyCallCheck] = useState(false);

  const [changePassAlert, setChangePassAlert] = useState(false);

  let search = window.location.search;
  let currentHref = window.location.href;
  let params = new URLSearchParams(search);

  let environment = params.get("env");
  let env =
    environment == "ALPHA"
      ? "alpha.superr.app"
      : environment == "BETA"
      ? "beta.superr.app"
      : environment == "TEST"
      ? "test.superr.app"
      : "prod.superr.app";

  useEffect(() => {
    let code = params.get("code");
    // ifLinkValid(code)
    if (code && !alreadyCalledCheck) {
      setAlreadyCallCheck(true);
      ifLinkValid(code);
    } else {
      setCodeQuery(null);
    }
  }, []);

  const ifLinkValid = async (code) => {
    if (code != null) {
      axios
        .get(
          `https://${env}/api/auth/v1/forgot-password/expire-check?link=${
            currentHref.replaceAll("&", "~")
            // "https://auth.superr.app/reset-password?code=759915a0e472555db324e10dbce943c1:35b7c893::759915a0e472555db324e10dbce943c1:6ee289cb4a40640917421446b25f503b49e6a0c1a3f64a9982~page=page_here~env=ALPHA"
          }`
        )
        .then((response) => {
          //console.log(response);
          if (!response?.data?.isExpired) {
            setCodeQuery(code);
          } else {
            setCodeQuery(null);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (!err?.response?.data?.data?.isExpired) {
            setCodeQuery(code);
          } else {
            setCodeQuery(null);
          }
        });
    } else {
      setCodeQuery(null);
    }
  };

  const validatePassword = (password, validate = true) => {
    if (validate) {
      const len = password.length < 6 || password.length > 20;
      const char = /\d/.test(password) && /[a-zA-Z]/g.test(password);
      setPassErrors({
        ...passErrors,
        length: len,
        characters: !char,
        confirmRequired: false,
        passRequired: false,
        isSame: false,
      });
      // //console.log('passValidation ' + password.length + ' . ' + len + ' . ' + char);
    } else {
      setPassErrors({
        ...passErrors,
        confirmRequired: false,
        passRequired: false,
        isSame: false,
      });
    }
    // return true;
  };

  const validateShouldCallApi = () => {
    const isSame = newPass.pass == newPass.confirmPass;

    // //console.log('shouldCallApi issame ' + `${isSame}`);
    // //console.log('shouldCallApi char ' + `${!passErrors.characters}`);
    // //console.log('shouldCallApi length ' + `${!passErrors.length}`);
    // //console.log('shouldCallApi confirmpass ' + `${newPass.confirmPass.length > 0}`);
    // //console.log('shouldCallApi newpass ' + `${newPass.pass.length > 0}`);
    // //console.log('shouldCallApi all  ' + `${isSame && !passErrors.characters && !passErrors.length && newPass.confirmPass.length > 0 && newPass.pass.length > 0}`);
    setFieldError({
      pass:
        newPass.pass.length < 1 || passErrors.characters || passErrors.length,
      confirmPass: !isSame || newPass.confirmPass.length < 1,
    });
    setPassErrors({
      ...passErrors,
      confirmRequired: newPass.confirmPass.length < 1,
      passRequired: newPass.pass.length < 1,
      isSame: !isSame,
    });
    return (
      isSame &&
      !passErrors.characters &&
      !passErrors.length &&
      newPass.confirmPass.length > 0 &&
      newPass.pass.length > 0
    );
  };

  const handleVisibilityChange = (event) => {
    if (event === "pass") {
      setShowPass({ ...showPass, pass: !showPass.pass });
    } else {
      setShowPass({ ...showPass, confirmPass: !showPass.confirmPass });
    }
  };

  const handleChange = (event) => {
    // //console.log('inputChange ' + event.target.name);
    setFieldError({
      pass: false,
      confirmPass: false,
    });
    setNewPass({ ...newPass, [event.target.name]: event.target.value });
    validatePassword(event.target.value, event.target.name == "pass");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validPass = validateShouldCallApi();
    // //console.log('apicall ' + validPass);
    if (validPass) {
      setChangePassAlert(true);
    }
  };

  const changePassAPIcall = () => {
    setChangePassAlert(false);
    setApiLoading(true);
    axios
      .post(`https://${env}/api/auth/v1/reset-password`, {
        password: newPass.confirmPass,
        code: codeQuery,
      })
      .then((response) => {
        setApiStatusSuccess(true);

        new Snackbar(response.data.msg, {
          position: "top-center",
          style: {
            container: [["background-color", "#00C853"]],
          },
        });
        setApiLoading(false);
        // setStatus(response.data.msg);
      })
      .catch((err) => {
        if (err.response.data.msg == "Link is already used or invalid ") {
          setCodeQuery(null);
        }
        new Snackbar(err.response.data.msg, {
          position: "top-center",
          timeout: 600,
          style: {
            container: [["background-color", "#E53935"]],
          },
        });
        setApiLoading(false);
        // setStatus(err.response.data.msg);
      });
  };

  return (
    <div className="reset_password">
      <DualLayout>
        {codeQuery === "loading" ? (
          <LoadingSpinner />
        ) : codeQuery != null ? (
          // true ?
          apiStatusSuccess ? (
            // false
            <div className="main-box pass-change-success">
              <Lottie animationData={PassSuccess} loop={false} />
              <div className="content">
                <h2>Password Updated</h2>
                <p>Awesome, you've successfully updated your password.</p>
                <p>
                  {" "}
                  Your password has been changed successfully. You can login
                  with this new password from now.{" "}
                </p>
              </div>
            </div>
          ) : (
            <Box
              className="main-box"
              sx={{
                maxWidth: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <VpnKeyIcon sx={{ fontSize: 50, color: "#ffffff" }} />
              </Box>
              <Box
                sx={{ display: { xs: "block", sm: "none" } }}
                className="small-logo"
              >
                <img src="/images/logo.png" alt="logo" height={80}></img>
              </Box>

              <Typography component="h1" variant="h5">
                Set new password
              </Typography>
              <div className="margin-top-10"></div>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <div className="label-title">
                  <InputLabel>Password</InputLabel>
                  {passErrors.passRequired ? <p>(Required)</p> : <></>}
                </div>
                <div className="margin-top-5"></div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="pass"
                  // label="Password"
                  type={showPass.pass ? "text" : "password"}
                  id="password"
                  sx={{
                    input: {
                      // color: "white",
                    },
                  }}
                  className={`input-text-field${
                    fieldError.pass ? "-error" : ""
                  }`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleVisibilityChange("pass")}
                          edge="end"
                          name="pass"
                        >
                          {showPass.pass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={newPass.pass}
                  onChange={handleChange}
                />
                <PasswordValidation validations={passErrors} />
                <div className="margin-top-15"></div>

                <div className="label-title">
                  <InputLabel>Confirm password</InputLabel>
                  {passErrors.confirmRequired ? <p>(Required)</p> : <></>}
                </div>
                <div className="margin-top-5"></div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPass"
                  // label="Password"
                  type={showPass.confirmPass ? "text" : "password"}
                  id="confirm password"
                  sx={{
                    input: {
                      // color: "white",
                    },
                  }}
                  className={`input-text-field${
                    fieldError.confirmPass ? "-error" : ""
                  }`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleVisibilityChange("confirmPass")}
                          edge="end"
                        >
                          {showPass.confirmPass ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={newPass.confirmPass}
                  onChange={handleChange}
                />
                <ConfirmPasswordValidation validations={passErrors} />
                <LoadingButton
                  loading={apiLoading}
                  loadingPosition="start"
                  startIcon={<></>}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Set new password
                </LoadingButton>
              </Box>
            </Box>
          )
        ) : (
          <div className="main-box pass-change-success">
            <Lottie animationData={NotFound} loop={false} />
            <div className="content margin-top--25">
              <h2>Link expired</h2>
              <p>
                Sorry, this link has expired. Please request a password change
                again from the Superr parents' app by tapping on the{" "}
                <span className="italic">
                  {" "}
                  Avatar {">"} Profile{">"} Change password {">"} Forgot
                  password
                </span>
              </p>
            </div>
          </div>
        )}
      </DualLayout>
      <Dialog
        open={changePassAlert}
        onClose={() => setChangePassAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disablePortal
        // disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">
          {"You will be logged out of Superr parents!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you continue, your password will be changed and you'll need to
            sign into your Superr parents app again on all your devices.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangePassAlert(false)}>Cancel</Button>
          <Button onClick={() => changePassAPIcall()} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResetPassword;

function PasswordValidation({ validations }) {
  return (
    <div className="validations">
      {/* {
                validations.passRequired === true ?
                    <div className={'val-item  error'}>
                        <div >
                            {
                                <Cancel fontSize="small" />
                            }
                        </div>
                        <div >
                            <p>Required</p>
                        </div>
                    </div> : <></>
            } */}

      <div
        className={`val-item ${
          validations.length === true
            ? "error"
            : validations.length === false
            ? "success"
            : ""
        }`}
      >
        <div>
          {validations.length === false ? (
            <CheckCircle fontSize="small" />
          ) : validations.length === true ? (
            <Cancel fontSize="small" />
          ) : (
            <FiberManualRecordIcon fontSize="small" />
          )}
        </div>
        <div>
          <p>Must be between 6-20 characters long</p>
        </div>
      </div>
      <div
        className={`val-item ${
          validations.characters === true
            ? "error"
            : validations.characters === false
            ? "success"
            : ""
        }`}
      >
        <div>
          {validations.characters === false ? (
            <CheckCircle fontSize="small" />
          ) : validations.characters === true ? (
            <Cancel fontSize="small" />
          ) : (
            <FiberManualRecordIcon fontSize="small" />
          )}
        </div>
        <div>
          <p>Use numbers(0-9) & letters(A-Z)</p>
        </div>
      </div>
    </div>
  );
}

function ConfirmPasswordValidation({ validations }) {
  return (
    <div className="validations">
      {/* {
                validations.confirmRequired === true ?
                    <div className={'val-item error'}>
                        <div >
                            {
                                <Cancel fontSize="small" />
                            }
                        </div>
                        <div >
                            <p>Required</p>
                        </div>
                    </div> : <></>
            } */}
      {validations.confirmRequired !== true && validations.isSame === true ? (
        <div className=" val-item error">
          <div>
            <Cancel fontSize="small" />
          </div>
          <div>
            <p>Both passwords do not match</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
