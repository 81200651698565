import React, { useEffect, useState } from "react";
import "./email-verified.css";

import axios from "axios";
import Lottie from "lottie-react";
import NotFound from "../assets/lottie/404.json";
import EmailSuccess from "../assets/lottie/email-verified.json";
import LoadingSpinner from "../components/loading";
import DualLayout from "../layout/dual-layout";

function EmailVerified() {
  const [apiLoading, setApiLoading] = useState(true);
  const [apiStatusSuccess, setApiStatusSuccess] = useState(false);
  const [apiCall, setApiCall] = useState(0);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  let environment = params.get("env");
  let env =
    environment == "ALPHA"
      ? "alpha.superr.app"
      : environment == "BETA"
      ? "beta.superr.app"
      : environment == "TEST"
      ? "test.superr.app"
      : "prod.superr.app";

  useEffect(() => {
    let code = params.get("code");

    // verifyEmail("99377c3f9d1f9697563693fbe3e03f42:f3f49569::99377c3f9d1f9697563693fbe3e03f42:baaad5280484827eb6918c4d3eb5c7f52e2acc7bbe::99377c3f9d1f9697563693fbe3e03f42:f9f495");
    // //console.log('i fire once');

    if (code != "" && code != null) {
      verifyEmail(code);
    } else {
      setApiLoading(false);
      setApiStatusSuccess(false);
    }
  }, []);

  const verifyEmail = async (code) => {
    setApiCall(1);
    //console.log("veridypass " + "inside");
    const splitCode = code.split("::");
    if (apiCall == 0) {
      axios
        .post(`https://${env}/api/auth/v1/email/verify`, {
          email: splitCode[1],
          userId: splitCode[2],
          secret: splitCode[0],
        })
        .then((response) => {
          setApiLoading(false);
          setApiStatusSuccess(true);
        })
        .catch((err) => {
          setApiLoading(false);
          setApiStatusSuccess(false);
        });
    }
  };
  return (
    <>
      <DualLayout>
        {apiLoading ? (
          <LoadingSpinner />
        ) : apiStatusSuccess ? (
          <div className="main-box email-verify-success">
            <Lottie animationData={EmailSuccess} loop={false} />
            <div className="content">
              <h2>Email Verified</h2>
              <p>Awesome, you've successfully verified your email.</p>
              <p>You can now continue being a Superr parent.</p>
            </div>
          </div>
        ) : (
          <div className="main-box email-verify-success">
            <Lottie animationData={NotFound} loop={false} />
            <div className="content">
              <h2>Oops! Email Verification Failed</h2>
              <p>
                Could you please try to verify your email again as there seem to
                be some unforeseen issues.
              </p>
            </div>
          </div>
        )}
      </DualLayout>
    </>
  );
}

export default EmailVerified;
