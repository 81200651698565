import React, { useState, useEffect } from "react";
import MobileFirstLayout from "../../../layout/mobile-first-layout";
import PlanSelectionTabs from "./components/plan-tabs";
import PlanSelectionFooter from "./components/plan-selection";
import { useCookies } from "react-cookie";
import { fetchSubscriptionPlansApi } from "../../../api/subscription-api";
import Snackbar from "awesome-snackbar";
import { LoadingComponent } from "../components/p_states";
import { getPlansUi } from "./functions/plans-functions";
import PlanFeatureTable from "./components/feature-table";
import TierFeatureAccordion from "./components/tier-feature-accordion";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SpecialOfferPage from "./components/special-offer";
import OfferSelectionFooter from "./components/offer-plan-selection";

function PlanDetailsPage() {
  const navigate = useNavigate();
  const [cookies] = useCookies(["user_token"]);
  const [fetchPlansLoading, setFetchPlansLoading] = useState(true);
  const [planUi, setPlanUi] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedOfferPlan, setSelectedOfferPlan] = useState(null);
  const [showSinglePlanUI, setShowSinglePlanUi] = useState(false);
  const [planDataApiRes, setPlanDataApiRes] = useState(null);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isChoosePlan = params.get("isChoosePlan");

  useEffect(() => {
    const fetchPlansDetails = async () => {
      setFetchPlansLoading(true);
      try {
        const token = cookies.user_token;
        const response = await fetchSubscriptionPlansApi(token);
        // console.log(response.data);
        if (response.data.hasActivePlan === true && !isChoosePlan) {
          // navigate(`/account`);
          return;
        }
        setPlanDataApiRes(response.data);
        if (
          response.data.couponPricing != null &&
          response.data.couponPricing.length > 0
        ) {
          setShowSinglePlanUi(true);
          setSelectedOfferPlan(response.data.couponPricing[0]);
        }
        doSideActions(
          response.data.subscriptionPlans,
          response.data.activeSubscription
        );
      } catch (err) {
        new Snackbar("Issue retrieving plans could you try again!", {
          position: "top-center",
          timeout: 600,
          style: {
            container: [["background-color", "#E53935"]],
          },
        });
      }
    };
    fetchPlansDetails();
  }, [cookies]);

  const doSideActions = (data, active) => {
    // console.log(data);
    const ui = getPlansUi(data, active);
    setPlanUi(ui);
    const selectedTier = ui.paywallData.find(
      (tier) => tier.identifier === ui.selectedPlan.tierIdentifier
    );
    setSelectedTier(selectedTier);
    setFetchPlansLoading(false);
  };

  const handleSegmentChange = (segment) => {
    const selectedTier = planUi.paywallData[segment];
    setSelectedTier(selectedTier);
    // console.log(selectedTier);
  };

  return (
    <div className="plan-details">
      <div
        className={`common-layout ${
          showSinglePlanUI ? "base-style" : selectedTier?.style
        }`}
      >
        <MobileFirstLayout
          title={
            showSinglePlanUI
              ? "Special Offer!"
              : isChoosePlan === "true"
              ? "Choose plan"
              : "Manage Plans"
          }
          fixedChild={
            showSinglePlanUI ? (
              <OfferSelectionFooter
                offerPlans={planDataApiRes.couponPricing}
                onSeeAllPlans={() => {
                  setShowSinglePlanUi(false);
                }}
                selectedOfferPlan={selectedOfferPlan}
                setSelectedOfferPlan={setSelectedOfferPlan}
              />
            ) : planUi != null && selectedTier != null ? (
              <PlanSelectionFooter
                plans={selectedTier.plans}
                initialSelectedPlan={
                  planUi.selectedPlan.tierIdentifier ===
                  selectedTier?.identifier
                    ? planUi.selectedPlan
                    : selectedTier?.plans[0]
                }
              />
            ) : null
          }
        >
          <Box sx={{ paddingBottom: "40vh" }}>
            {fetchPlansLoading ? (
              <div style={{ width: "100%" }}>
                <LoadingComponent />
                <p style={{ opacity: 0 }}>
                  What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry.
                </p>
              </div>
            ) : showSinglePlanUI ? (
              <SpecialOfferPage
                offerDetails={planDataApiRes.couponPricing}
                setSelectedOfferPlan={setSelectedOfferPlan}
              />
            ) : (
              <>
                <p>{isChoosePlan === "true" ? "" : "Change Plan"}</p>
                <div className="mb-10" />
                <PlanSelectionTabs
                  segments={planUi.paywallData.map((plan) => plan.displayName)}
                  currentSegment={planUi.paywallData.findIndex(
                    (tier) => tier.identifier === selectedTier?.identifier
                  )}
                  onChange={(index) => handleSegmentChange(index)}
                />
                <div className="mb-10" />
                <PlanFeatureTable
                  tiers={planUi.paywallData.map((tier) => ({
                    name: tier.displayName,
                    id: tier.identifier,
                  }))}
                  features={planUi.featuresTable}
                  selectedTier={selectedTier?.identifier}
                />
                <TierFeatureAccordion tier={selectedTier} />
              </>
            )}
          </Box>
        </MobileFirstLayout>
      </div>
    </div>
  );
}

export default PlanDetailsPage;
