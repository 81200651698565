import React, { useState } from "react";
import "./subscription-card.scss";
import { Button, Box, Typography, IconButton } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../../components/alert/alert";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import RoundedDialogActionButton from "../../../../components/dialog-action-button";
import { show } from "@intercom/messenger-js-sdk";

// Utility Functions
const getDifferenceInDate = (date) => {
  const expiresOn = new Date(date).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  const difference = (expiresOn - today) / (1000 * 60 * 60 * 24);
  return difference < 0 ? 0 : difference + 1;
};

const getDaysLeft = (date) => {
  const daysLeft = getDifferenceInDate(date);
  return `${daysLeft} day${daysLeft > 1 ? "s" : ""} left`;
};

const getExpiryChipColor = (planStatus, expiresOn) => {
  switch (planStatus) {
    case "grace_period":
      return { bg: "#FFF9E7", color: "#A67E04" };
    case "expired":
    case "cancelled":
      return { bg: "#FDDADA", color: "#E53935" };
    case "active":
      const daysToExpiry = getDifferenceInDate(expiresOn);
      if (daysToExpiry > 0 && daysToExpiry < 4) {
        return { bg: "#FFF9E7", color: "#A67E04" };
      }
      return { bg: "#FFFFFF", color: "#0D47A1" };
    default:
      return { bg: "#FFFFFF", color: "#0D47A1" };
  }
};

const getPlanType = (data) => {
  if (!data) return "no_plan";
  switch (data.subscriptionType) {
    case "one_time":
      return "one_time";
    case "recurring":
      return "recurring";
    default:
      return "freeTrial";
  }
};

const getPlanExpiryStatus = (data) => {
  if (!data) return "no_plan";
  const { subscriptionType, expiresOn, recurringPaymentStatus } = data;
  const expiryDate = new Date(expiresOn);

  if (subscriptionType === "one_time") {
    return expiryDate < new Date() ? "expired" : "active";
  }

  switch (recurringPaymentStatus) {
    case "processing":
      return "grace_period";
    case "cancelled":
      return "cancelled";
    case "active":
      return expiryDate < new Date() ? "expired" : "active";
    default:
      return "no_plan";
  }
};

// Helper components
const ExpiryChip = ({ bg, color, text }) => (
  <Box
    sx={{
      padding: "4px 12px",
      backgroundColor: bg,
      border: `1px solid ${color}4D`,
      borderRadius: "100px",
    }}
  >
    <p style={{ color, fontWeight: 600, fontSize: "0.8em" }}>{text}</p>
  </Box>
);

const CurrentSubscriptionCard = ({ freeTrialData, paidPlanData }) => {
  const navigate = useNavigate();
  const [showInappDialog, setShowInappDialog] = useState(false);
  const data = freeTrialData || paidPlanData;
  const planStatus = getPlanExpiryStatus(data);
  const planType = getPlanType(data);
  //console.log(data, planStatus, planType);
  const ui = (() => {
    switch (planType) {
      case "no_plan":
        return createNoPlanCard();
      case "one_time":
        return createPlanCard(data, planStatus, "one_time");
      case "recurring":
        return createPlanCard(data, planStatus, "recurring");
      case "freeTrial":
        return createPlanCard(data, planStatus, "freeTrial");
      default:
        return createNoPlanCard();
    }
  })();

  return (
    <div className="subscription-card" style={{ backgroundColor: ui.cardBg }}>
      <div className="subscription-card__content">
        <div className="subscription-card__header">
          {ui.leftTitle && <h2>{ui.leftTitle}</h2>}
          {ui.rightTitle && (
            <ExpiryChip
              bg={ui.rightTitleBg}
              color={ui.rightTitleColor}
              text={ui.rightTitle}
            />
          )}
        </div>
        {ui.infoBoxContent && (
          <div className="subscription-card__info">
            <p>{ui.infoBoxContent}</p>
          </div>
        )}
        {ui.headline && (
          <div className="subscription-card__headline">
            <p>{ui.headline}</p>
          </div>
        )}
        {ui.subtitle && (
          <div className="subscription-card__subtitle">
            <p>{ui.subtitle}</p>
          </div>
        )}
        {ui.autoRenewTitle && ui.autoRenewsubTitle && (
          <div className="subscription-card__auto-renew">
            <p>{ui.autoRenewTitle}</p>
            <p style={{ color: ui.autoRenewsubTitleColor }}>
              {ui.autoRenewsubTitle}
            </p>
          </div>
        )}
        <HelpCard />
        {ui.showPrimaryAction && ui.primaryActionLabel && (
          <div className="subscription-card__action">
            <Button
              variant="contained"
              onClick={() => {
                if (ui.isInAppPurchase === true) {
                  setShowInappDialog(true);
                } else {
                  navigate("/plans?isChoosePlan=true");
                }
              }}
            >
              {ui.primaryActionLabel}
            </Button>
          </div>
        )}
      </div>
      <CustomAlert
        open={showInappDialog}
        setOpen={setShowInappDialog}
        title={"Subscribed Via App?"}
        subtitle={
          "For seamless management of your subscription, navigate to 'Subscription' under 'Settings' in the app."
        }
        imageSrc={"/images/subscription.gif"}
        actions={[
          <RoundedDialogActionButton
            label={"Cancel"}
            onClick={() => setShowInappDialog(false)}
            color="default"
            icon={null}
          />,
          <RoundedDialogActionButton
            label={"Use app"}
            onClick={() => {
              setShowInappDialog(false);
              window.open(
                "https://superrapp.page.link/parents-app",
                "_blank",
                "noreferrer"
              );
            }}
            color="primary"
            icon={<OpenInNewRoundedIcon />}
          />,
        ]}
        primaryColor={"#17437D"}
      />
    </div>
  );
};

// Utility functions
const createNoPlanCard = () => ({
  cardBg: "#E3F2FD",
  cardBgImageColor: "#FFFFFF",
  leftTitle: "No Active Plan",
  infoBoxContent:
    "Renew your plan to continue protecting your children and support their learning with Superr.",
  showPrimaryAction: true,
  primaryActionLabel: "Resubscribe",
  rightTitleBg: "",
  rightTitleColor: "",
  headline: "",
  strikeThroughSubtitle: "",
  subtitle: "",
  subtitleColor: "",
  autoRenewTitle: "",
  autoRenewsubTitle: "",
  autoRenewsubTitleColor: "",
  showCustomerSupport: false,
  planStatus: "",
  planType: "no_plan",
  isInAppPurchase: false,
});

const createPlanCard = (data, planStatus, type) => {
  const isWebPayment = data.subscriptionPlatform === "web";
  const baseCard = {
    cardBg: "#E3F2FD",
    cardBgImageColor: "#FFFFFF",
    leftTitle: `${
      planStatus === "expired" ? "No Active " : `${data.tier}`
    } Plan`,
    headline: `${data.discountedPrice || data.price} / ${data.validity}`,
    showPrimaryAction: true,
    planStatus: planStatus,
    planType: type,
    isInAppPurchase: !isWebPayment,
  };
  //console.log(planStatus);
  switch (type) {
    case "one_time":
      return {
        ...baseCard,
        rightTitle: getPlanStatusString(planStatus, data.expiresOn),
        rightTitleBg: getExpiryChipColor(planStatus, data.expiresOn).bg,
        rightTitleColor: getExpiryChipColor(planStatus, data.expiresOn).color,
        subtitle:
          planStatus === "expired"
            ? "Your subscription has expired"
            : `Expires on ${new Date(data.expiresOn).toLocaleDateString()}`,
        subtitleColor: planStatus === "expired" ? "#E53935" : "#757575",
        primaryActionLabel:
          planStatus === "expired" ? "Resubscribe" : "Manage subscription",
        infoBoxContent: "",
        strikeThroughSubtitle: "",
        autoRenewTitle: "",
        autoRenewsubTitle: "",
        autoRenewsubTitleColor: "",
        showCustomerSupport: false,
      };
    case "recurring":
      return {
        ...baseCard,
        rightTitle: getPlanStatusString(planStatus, data.expiresOn),
        rightTitleBg: getExpiryChipColor(planStatus, data.expiresOn).bg,
        rightTitleColor: getExpiryChipColor(planStatus, data.expiresOn).color,
        autoRenewTitle:
          planStatus !== "expired" &&
          planStatus !== "grace_period" &&
          planStatus !== "cancelled"
            ? "Auto Renews on"
            : "Auto Renew",
        autoRenewsubTitle:
          planStatus === "cancelled"
            ? "Cancelled"
            : planStatus === "grace_period"
            ? "Payment processing"
            : planStatus === "expired"
            ? "Payment Failed"
            : new Date(data.nextPaymentDate).toLocaleDateString(),
        autoRenewsubTitleColor: getExpiryChipColor(planStatus, data.expiresOn)
          .color,
        primaryActionLabel:
          planStatus === "expired" || planStatus === "cancelled"
            ? "Resubscribe"
            : "Manage subscription",
        showPrimaryAction: planStatus !== "grace_period",
        showCustomerSupport: planStatus === "grace_period",
        infoBoxContent: "",
        strikeThroughSubtitle: "",
        subtitle: "",
        subtitleColor: "",
      };
    case "freeTrial":
      return {
        ...baseCard,
        cardBg: "rgba(226, 250, 228, 1)",
        cardBgImageColor: "#388E3C",
        headline: "Superr Free trial",
        subtitle: `Trial expires on ${new Date(
          data.expiresOn
        ).toLocaleDateString()}`,
        showPrimaryAction: true,
        primaryActionLabel: "Upgrade Now",
        rightTitle:
          planStatus === "expired" ? "Expired" : getDaysLeft(data.expiresOn),
        rightTitleBg: getExpiryChipColor(planStatus, data.expiresOn).bg,
        rightTitleColor: getExpiryChipColor(planStatus, data.expiresOn).color,
        subtitleColor: planStatus === "expired" ? "#E53935" : "#757575",
        infoBoxContent: "",
        strikeThroughSubtitle: "",
        autoRenewTitle: "",
        autoRenewsubTitle: "",
        autoRenewsubTitleColor: "",
        showCustomerSupport: false,
        isInAppPurchase: false,
      };
    default:
      return createNoPlanCard();
  }
};

const getPlanStatusString = (planStatus, expiresOn) => {
  switch (planStatus) {
    case "grace_period":
      return "Processing";
    case "expired":
      return "Expired";
    case "active":
      const daysToExpiry = getDifferenceInDate(expiresOn);
      //console.log(daysToExpiry, getDaysLeft(expiresOn));
      return daysToExpiry > 0 ? getDaysLeft(expiresOn) : "Expired";
    default:
      return null;
  }
};

export default CurrentSubscriptionCard;

const HelpCard = () => {
  return (
    <Box
      className="help-card"
      onClick={() => {
        show();
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={1.5}
        sx={{ padding: "8px 12px" }}
      >
        <Box display="flex" alignItems="center">
          <HelpOutlineIcon color="primary" />
          <Typography variant="body1" ml={1} color="primary">
            Need help? Get in touch.
          </Typography>
        </Box>
        <IconButton edge="end" size="small" colo>
          <ChevronRightIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
};
