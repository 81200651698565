import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Styled component for the gradient text
const GradientText = styled("span")({
  backgroundImage:
    "linear-gradient(to right, #0661bc, #06c 25%, #1e8fe1 51%, #1e8fe1 79%, #1e8fe1)",
  WebkitTextFillColor: "transparent",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
});

const slides = [
  {
    img: "https://superr.app/images/slides/safety-slide-lg.png",
    title: "digital safety",
  },
  {
    img: "https://superr.app/images/slides/learning-slide-lg.png",
    title: "study companion",
  },
  {
    img: "https://superr.app/images/slides/for_parents-slide-lg.png",
    title: "digital parenting",
  },
];

function ImageSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0",
        position: "relative",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          textAlign: "center",
          padding: "10px",
          marginTop: "20px",
          width: "100%",
          zIndex: 1,
        }}
      >
        The most complete{" "}
        <GradientText>{slides[currentSlide].title}</GradientText> app
      </Typography>
      <img
        src={slides[currentSlide].img}
        alt={slides[currentSlide].title}
        style={{ width: "100%", height: "auto", zIndex: 1 }}
      />
    </Box>
  );
}

export default ImageSlider;
