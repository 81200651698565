import React from "react";
import "./dual-layout.scss";

import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
const theme = createTheme();

function DualLayout(props) {
  return (
    <div className="dual_layout">
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={false}
            md={5}
            lg={6}
            sx={{
              backgroundImage: "url(/images/left_header.png)",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#0D47A1",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={6}
            component={Paper}
            elevation={20}
            square
            className="main-right position-relative"
          >
            {props.showLogo === true ? (
              <>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    position: "absolute",
                    padding: "12px 0px 0px 12px",
                  }}
                  className="small-logo"
                >
                  <img src="/images/logo.png" alt="logo" height={50}></img>
                </Box>
                <div style={{ marginTop: props.topPadding }}></div>
              </>
            ) : (
              <></>
            )}
            {props.children}
            <div className="w-100 position-fixed" style={{ bottom: 0 }}>
              {props.footer}
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default DualLayout;
