import React, { useState } from "react";
import "./specialOffer.scss";
import TierFeatureAccordion from "./tier-feature-accordion";

const SpecialOfferPage = ({
  offerDetails,
  selectedOfferPlan,
  setSelectedOfferPlan,
}) => {
  const isSinglePlan = offerDetails.length === 1;
  if (isSinglePlan) {
    return <SinglePlanOfferComponent offerDetails={offerDetails} />;
  } else {
    return (
      <MultiplePlanOfferComponent
        offerDetails={offerDetails}
        selectedOfferPlan={selectedOfferPlan}
        setSelectedOfferPlan={setSelectedOfferPlan}
      />
    );
  }
};

const SinglePlanOfferComponent = ({ offerDetails }) => {
  const plan = offerDetails[0].plans[0];
  const orginalPrice = `${plan.currency}${plan.price}`;
  const discountedPrice = `${plan.currency}${plan.discountedPrice}`;
  const duration =
    parseInt(plan.units) === 30
      ? "per month"
      : parseInt(plan.units) === 90
      ? "every 3 months"
      : parseInt(plan.units) === 365
      ? "per year"
      : parseInt(plan.units) === 548
      ? "For 1yr 6mon"
      : `${parseInt(plan.units)} days`;
  return (
    <div className="special-offer">
      <div className="offer-card-container">
        <div className="offer-card">
          <div className="offer-from">
            <p className="label">From</p>
            <h2 className="from">
              <span className="icon">🏫</span> {offerDetails[0].couponTitle}.
            </h2>
          </div>
          <div className="promo-code">
            <p className="label">Promo code applied</p>
            <h3 className="code">
              <span className="icon">✨</span>
              {offerDetails[0].couponCode}
            </h3>
          </div>
        </div>
      </div>
      <h2 className="unlock-text">{plan.discountText}</h2>
      <div className="offer-details">
        <p>
          <span className="checkmark">✓</span> Only{" "}
          <span className="strikethrough">{orginalPrice}</span>{" "}
          <span className="discounted-price">{discountedPrice}</span> {duration}
        </p>
        <p>
          <span className="checkmark">✓</span> For all children in the family
        </p>
        <p>
          <span className="checkmark">✓</span> Includes all premium features
        </p>
      </div>
      <TierFeatureAccordion tier={offerDetails[0]} />
    </div>
  );
};

const MultiplePlanOfferComponent = ({
  offerDetails,
  selectedOfferPlan,
  setSelectedOfferPlan,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(
    selectedOfferPlan || offerDetails[0]
  );
  const [expandedTiers, setExpandedTiers] = useState({});

  const handlePlanSelection = (offer) => {
    setSelectedPlan(offer);
    setSelectedOfferPlan(offer);
  };

  const formatDuration = (baseUnits, units) => {
    const totalDays = units;
    const years = Math.floor(totalDays / 365);
    const months = Math.floor((totalDays % 365) / 30);
    const days = totalDays % 30;

    if (years > 0) {
      if (months >= 6) {
        return `${years + 1} year${years + 1 > 1 ? "s" : ""}`;
      } else {
        return `${years} year${years > 1 ? "s" : ""}`;
      }
    } else if (months > 0) {
      if (days >= 15) {
        return `${months + 1} month${months + 1 > 1 ? "s" : ""}`;
      } else {
        return `${months} month${months > 1 ? "s" : ""}`;
      }
    } else {
      return `${days} day${days > 1 ? "s" : ""}`;
    }
  };

  return (
    <div className="special-offer">
      <div className="offer-card-container">
        <div className="offer-card">
          <div className="offer-from">
            <p className="label">From</p>
            <h2 className="from">
              <span className="icon">🏫</span> {offerDetails[0].couponTitle}
            </h2>
          </div>
          <div className="promo-code">
            <p className="label">Promo code applied</p>
            <h3 className="code">
              <span className="icon">✨</span>
              {offerDetails[0].couponCode}
            </h3>
          </div>
        </div>
      </div>
      <h2 className="unlock-text">{selectedPlan.plans[0].discountText}</h2>
      <div className="plan-selection">
        {offerDetails.map((offer) => {
          const plan = offer.plans[0];
          const isSelected = selectedPlan.id === offer.id;
          const discountType = plan.couponDetails.discountType;
          const originalPrice = `${plan.currency}${plan.price}`;
          const discountedPrice = `${plan.currency}${plan.discountedPrice}`;
          const perMonth = `${plan.currency}${Math.round(
            plan.discountedPrice / 12
          )}/month`;

          let discountText;
          if (discountType === "unit") {
            const baseDuration = formatDuration(plan.baseUnits, plan.baseUnits);
            const extraDuration = formatDuration(
              0,
              plan.units - plan.baseUnits
            );
            discountText = `${baseDuration} + ${extraDuration} ✨ Free`;
          } else if (discountType === "price") {
            const savings = plan.price - plan.discountedPrice;
            discountText = `Save ${plan.currency}${savings}`;
          }

          return (
            <div key={offer.id} className="planCard">
              <div
                className={`plan-option ${isSelected ? "selected" : ""}`}
                onClick={() => handlePlanSelection(offer)}
              >
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handlePlanSelection(offer)}
                />
                <div className="plan-details">
                  <h3>{offer.displayName}</h3>
                  <p className="duration">
                    {/* {formatDuration(plan.baseUnits, plan.units)} */}
                  </p>
                  <p className="discount mt-2">{discountText}</p>
                </div>
                <div className="price-details">
                  <p className="price">
                    {discountType === "price" ? (
                      <span className="strikethrough">{originalPrice}</span>
                    ) : (
                      <span>{discountedPrice}</span>
                    )}
                    <span className="discounted-price">{perMonth}</span>
                  </p>
                </div>
              </div>
              <TierFeatureAccordion tier={offer} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SpecialOfferPage;
