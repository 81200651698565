import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React from "react";
import "./alert.scss";

import { makeStyles } from "@mui/styles";

const Styles = makeStyles((theme) => ({
  title: {
    color: "#17437d !important",
    fontSize: "22px !important",
    fontWeight: "600 !important",
  },
  subtitle: {
    color: "#797979 !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
  },
  contentPadding: {
    padding: "20px 24px 0px 24px !important",
  },
  actions: {
    "& > *": {
      // This targets all direct children of DialogActions
      width: "100% !important", // Make each button take full width
      margin: "6px !important",
      height: "52px !important",
      textTransform: "Capitalize !important",
      fontSize: "16px !important",
    },
  },
}));

export default function CustomAlert({
  open,
  setOpen,
  title,
  subtitle,
  imageSrc,
  actions,
  primaryColor,
}) {
  const classes = Styles();

  const handleClose = (buttonAction) => () => {
    setOpen(false);
    buttonAction && buttonAction();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classname="custom_alert"
      PaperProps={{
        style: {
          maxWidth: "500px", // Set maximum width for larger screens
          width: "100%", // Use full width on smaller screens
          margin: "20px", // Add margin to avoid touching the screen edges
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className={classes.contentPadding}
      >
        {imageSrc && (
          <img
            src={imageSrc}
            alt=""
            style={{ width: "60%", marginBottom: 10, borderRadius: "8px" }}
          />
        )}
        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center" }}
          className={classes.title}
        >
          {title}
        </DialogContentText>

        <DialogContentText
          id="alert-dialog-description"
          style={{ textAlign: "center" }}
          className={classes.subtitle}
        >
          {subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.actions}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          margin: "8px",
        }}
      >
        {actions.map((action, index) => action)}
      </DialogActions>
    </Dialog>
  );
}
