

import { useEffect } from 'react';
import './App.css';


function RedirectPage() {

    useEffect(() => {

        window.location.replace('https://superr.app/')
        return () => {

        }
    }, [])


    return (
        <div>
            <p>Loading</p>
        </div>
    );
}

export default RedirectPage;