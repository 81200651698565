import React, { useEffect } from "react";

import { show } from "@intercom/messenger-js-sdk";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Box from "@mui/material/Box";
import RoundedDialogActionButton from "../../components/dialog-action-button";
import MobileFirstLayout from "../../layout/mobile-first-layout";
import { PaymentProcessing, PaymentSuccess } from "./components/p_states";
import { useCookies } from "react-cookie";
import { updatePaymentStatus } from "../../api/subscription-api";

function PaymentCompleted() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let status = params.get("status");
  let linkId = params.get("linkId");
  const [cookies, setCookie] = useCookies(["user_token"]);
  const token = cookies.user_token;

  useEffect(() => {
    initialFunction();
  }, [token, linkId]);

  const initialFunction = async () => {
    if (linkId != null) {
      updatePaymentStatus(token, linkId);
    }
  };

  function GetUI() {
    if (status === "failure") {
      return (
        <PaymentProcessing
          label={
            "Processing payment. We will notify you upon completion. You can also check the app for updates."
          }
        />
      );
    } else if (status === "success") {
      return (
        <PaymentSuccess label="Payment processed. You will be notified via the app." />
      );
    } else {
      return (
        <PaymentProcessing
          label={
            "Processing payment. We will notify you upon completion. You can also check the app for updates."
          }
        />
      );
    }
  }
  return (
    <MobileFirstLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0px 32px",
          height: "100%",
        }}
      >
        <GetUI />
        {status !== "success" ? (
          <div className="d-flex flex-column w-100 justify-content-center">
            <RoundedDialogActionButton
              label={"Contact Support"}
              onClick={() => {
                show();
              }}
              color="default"
              icon={null}
              style={{ height: "52px" }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex flex-column w-100 justify-content-center">
          <RoundedDialogActionButton
            label={"Use app"}
            onClick={() => {
              window.open(
                "https://superrapp.page.link/parents-app",
                "_blank",
                "noreferrer"
              );
            }}
            color="primary"
            icon={<OpenInNewRoundedIcon />}
            style={{ height: "52px" }}
          />
        </div>
      </Box>
    </MobileFirstLayout>
  );
}

export default PaymentCompleted;
