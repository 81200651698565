

import Lottie from "lottie-react";
import Loading from '../assets/lottie/loading.json';



function LoadingSpinner() {
    return (
        <div className='main-box pass-change-success'>
            <div className='loading-lottie'>
                <Lottie animationData={Loading} loop={true} />

            </div>

        </div>
    );
}

export default LoadingSpinner;