import React, { useCallback, useState } from "react";
import "./reset-password.scss";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Widget } from "@typeform/embed-react";
import DualLayout from "../layout/dual-layout";

import axios from "axios";

import Snackbar from "awesome-snackbar";
import DeleteForm from "../components/delete-form";

function DeleteAccount() {
  const [currentTab, setCurrentTab] = useState(1);
  const [userDetails, setUserDetails] = useState(null);
  const [accountDeleted, setAcountDeleted] = useState(false);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  let environment = params.get("env");
  let env =
    environment == "ALPHA"
      ? "alpha.superr.app"
      : environment == "BETA"
      ? "beta.superr.app"
      : environment == "TEST"
      ? "test.superr.app"
      : "prod.superr.app";
  //console.log("environment - " + env);

  const handleLoginDetails = (response) => {
    //console.log(response.data.data);
    setUserDetails(response.data.data);
  };

  const onDeleted = (response) => {
    //console.log(response.data.data);
    setAcountDeleted(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 1:
        return (
          <Login
            changeTab={setCurrentTab}
            env={env}
            onAuth={handleLoginDetails}
          />
        );
      case 2:
        return <AccountDeletionWarning changeTab={setCurrentTab} />;
      case 3:
        return (
          <AccountDeletion
            accountDeleted={onDeleted}
            env={env}
            userDetails={userDetails}
          />
        );
      default:
        return (
          <Login
            changeTab={setCurrentTab}
            env={env}
            onAuth={handleLoginDetails}
          />
        );
    }
  };

  return (
    <div>
      <DualLayout
        showLogo={true}
        topPadding={currentTab === 3 || currentTab === 2 ? "3vh" : "10px"}
      >
        <Box
          className="main-box"
          sx={{
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          {accountDeleted == true ? (
            <SadFarewell />
          ) : (
            <>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "1.7rem !important", // for extra-small screens
                    sm: "2rem !important", // for small screens
                    md: "2.1rem !important", // for medium screens
                    lg: "2.2rem !important", // for large screens
                  },
                }}
              >
                Deleting your Superr. account
              </Typography>
              <div className="margin-top-10"></div>

              {renderContent()}
            </>
          )}
        </Box>
      </DualLayout>
    </div>
  );
}

export default DeleteAccount;

function Login({ changeTab, env, onAuth }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setpass] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [required, setRequired] = useState({
    password: false,
    email: false,
  });

  const handleSubmit = async (event) => {
    let isValid = true;
    let updatedRequired = { ...required };

    if (!email || email.trim() === "") {
      updatedRequired.email = true;
      isValid = false;
    } else {
      updatedRequired.email = false;
    }

    if (!pass || pass.trim() === "") {
      updatedRequired.password = true;
      isValid = false;
    } else {
      updatedRequired.password = false;
    }

    setRequired(updatedRequired);
    //console.log("validationcheckpoint", updatedRequired, isValid);
    if (!isValid) {
      return;
    }
    setLoading(true);
    axios
      .post(`https://${env}/api/auth/v1/login`, {
        email: email,
        password: pass,
      })
      .then((response) => {
        changeTab(2);
        setLoading(false);
        onAuth(response);
      })
      .catch((err) => {
        new Snackbar(err.response.data.msg, {
          position: "top-center",
          timeout: 600,
          style: {
            container: [["background-color", "#E53935"]],
          },
        });
        setLoading(false);
        // setStatus(err.response.data.msg);
      });
  };

  return (
    <Box sx={{ mt: 1 }} className="reset_password">
      {_ContactSupprt()}
      <p
        style={{
          color: "#424242",
          fontSize: 14,
          fontFamily: "Euclid Circular B",
          fontWeight: "400",
          wordWrap: "break-word",
          padding: "8px 0px 4px 0px",
        }}
      >
        If you are sure you want to proceed with deleting, enter login details
        below
      </p>
      <div className="margin-top-5"></div>
      <div className="label-title">
        <InputLabel>Email</InputLabel>
        {required.email ? <p>(Required)</p> : <></>}
      </div>
      <div className="margin-top-5"></div>
      <TextField
        margin="normal"
        required
        fullWidth
        name="email"
        // label="Email"
        type={"email"}
        id="email"
        className={`input-text-field`}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      <div className="margin-top-15"></div>

      <div className="label-title">
        <InputLabel>Password</InputLabel>
        {required.password ? <p>(Required)</p> : <></>}
      </div>
      <div className="margin-top-5"></div>
      <TextField
        margin="normal"
        required
        fullWidth
        name="confirmPass"
        type={showPass ? "text" : "password"}
        id="confirm password"
        className={`input-text-field`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPass(!showPass)}
                edge="end"
              >
                {showPass ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={pass}
        onChange={(event) => setpass(event.target.value)}
      />

      <LoadingButton
        loading={loading}
        loadingPosition="start"
        startIcon={<></>}
        type="submit"
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
      >
        Confirm
      </LoadingButton>
    </Box>
  );
}

const SadFarewell = () => {
  const cardStyle = {
    borderRadius: "20px",
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    padding: "1rem",
    textAlign: "center",
    // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(255, 255, 255, 0.4)",
    maxWidth: "450px",
    width: "100%",
    margin: "auto",
  };

  const iconStyle = {
    fontSize: "40px",
    color: "#FF6B6B",
  };

  const titleStyle = {
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "1.8rem",
    color: "#333",
  };

  const subtitleStyle = {
    color: "#333",
    fontSize: "1rem",
    fontWeight: "bold",
    marginTop: "0.5rem",
  };

  const textStyle = {
    color: "#666",
    fontSize: "0.9rem",
    marginTop: "0.5rem",
  };

  const textStyleSmall = {
    color: "#666",
    fontSize: "0.8rem",
    marginTop: "0.7rem",
    fontStyle: "italic",
  };

  return (
    <div style={cardStyle}>
      {/* Replace 'HeartBrokenIcon' with your actual icon component */}
      <div style={iconStyle}>💔</div>
      <div style={titleStyle}>We'll miss you</div>
      <div style={subtitleStyle}>Your account has been deleted.</div>
      <div style={textStyle}>
        We hope you enjoyed your Superr. experience and we wish you all the very
        best in your parenting journey!
      </div>
      <div style={textStyleSmall}>
        You can always create a new account at any time by signing up again with
        your old login email or a new one.
      </div>
    </div>
  );
};

function _ContactSupprt() {
  return (
    <div
      style={{
        padding: 12,
        background: "#cadeff",
        borderRadius: 6,
      }}
    >
      <div style={{ alignSelf: "stretch" }}>
        <span
          style={{
            color: "#424242",
            fontSize: 14,
            fontFamily: "Euclid Circular B",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          If you’re having issues with Superr. please contact us first at{" "}
        </span>
        <a
          href="mailto:support@superr.app?subject=Assistance Needed with Superr Issues&body=Hi Support Team,%0D%0A%0D%0AI am experiencing issues with Superr and need assistance. Please find the details below:%0D%0A[Your detailed issue description here]%0D%0A%0D%0AThank you for your help."
          style={{
            color: "#2F80ED",
            fontSize: 14,
            fontFamily: "Euclid Circular B",
            fontWeight: "500",
            wordWrap: "break-word",
          }}
        >
          {" "}
          support@superr.app
        </a>
        <span
          style={{
            color: "#424242",
            fontSize: 14,
            fontFamily: "Euclid Circular B",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          . We’ll do our best to help you out.
        </span>
      </div>
    </div>
  );
}

function AccountDeletionWarning({ changeTab }) {
  const containerStyle = {
    maxWidth: "400px",
    fontFamily: "Euclid Circular B",
    color: "#333",
  };

  const listStyle = {
    listStyle: "disc",
    padding: "0 0 0 16px",
    margin: "0",
  };

  const listItemStyle = {
    marginBottom: "10px",
    fontSize: 16,
  };

  const boldTextStyle1 = {
    color: "#424242",
    fontSize: 18,
    fontFamily: "Euclid Circular B",
    fontWeight: "500",
    letterSpacing: 0.1,
    wordWrap: "break-word",
    margin: "0 0 8px 0",
  };

  const boldTextStyle2 = {
    color: "#EB5757",
    fontSize: 18,
    fontFamily: "Euclid Circular B",
    fontWeight: "500",
    letterSpacing: 0.1,
    wordWrap: "break-word",
    margin: "0 0 8px 0",
  };

  const redText = {
    color: "#EB5757",
  };

  const handleSubmit = (event) => {
    changeTab(3);
  };

  return (
    <div style={containerStyle}>
      <p style={boldTextStyle1}>
        Deleting your Superr. account will cause the following to occur and
        can’t be undone.
      </p>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          Your account and personal data will be permanently removed and you
          won’t be able to log in.
        </li>
        <li style={listItemStyle}>
          Your children’s device protection will be removed and will be
          automatically logged out.
        </li>
        <li style={listItemStyle}>
          Additional parents/guardians accounts linked with your family will
          lose their access to family data and will be automatically logged out.
        </li>
      </ul>
      <p style={boldTextStyle2}>Before you proceed</p>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          To avoid further charges{" "}
          <span style={redText}>cancel any active subscriptions</span> purchased
          via the App Store or Play Store.
        </li>
      </ul>
      <div className="margin-top-5"></div>
      <LoadingButton
        // loading={apiLoading}
        onClick={handleSubmit}
        loadingPosition="start"
        startIcon={<></>}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        Proceed to delete
      </LoadingButton>
    </div>
  );
}

function AccountDeletion({ accountDeleted, env, userDetails }) {
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const para = {
    color: "#424242",
    fontSize: 16,
    fontFamily: "Euclid Circular B",
    fontWeight: "400",
    letterSpacing: 0.1,
    wordWrap: "break-word",
    margin: "0 0 8px 0",
  };

  const iframeContainer = {
    backgroundColor: "black",
    borderRadius: "8px",
    width: "100%",
    height: "55vh",
  };

  const axiosDeleteInstance = axios.create({
    headers: {
      Authorization: userDetails.accessToken,
    },
  });

  const handleSubmit = async (event) => {
    setLoading(true);

    axiosDeleteInstance
      .get(`https://${env}/api/common/v1/type-form/account/delete`)
      .then((response) => {
        //console.log(response);
        if (response.data.data["total_items"] == 0) {
          new Snackbar(
            "Before proceeding with account deletion, it's important to complete the survey. Simply click on the 'Let's Do It' button to get started with the survey. Your feedback is valuable to us!",
            {
              position: "top-center",
              timeout: 6000,
              style: {
                container: [["background-color", "#E53935"]],
              },
            }
          );
        } else {
          axiosDeleteInstance
            .delete(`https://${env}/api/auth/v1/account`, {
              data: {
                userType: "guardian",
              },
            })
            .then((response) => {
              accountDeleted(response);
            })
            .catch((err) => {
              new Snackbar(err.response.data.msg, {
                position: "top-center",
                timeout: 600,
                style: {
                  container: [["background-color", "#E53935"]],
                },
              });

              // setStatus(err.response.data.msg);
            });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setLoading(false);
        new Snackbar(error.message, {
          position: "top-center",
          timeout: 600,
          style: {
            container: [["background-color", "#E53935"]],
          },
        });
      });
  };
  const handleButtonEnable = () => {
    setDisableButton(false);
  };

  const memoizedUpdateCount = useCallback(handleButtonEnable, [userDetails]);

  return (
    <>
      <p style={para}>
        Before you go, please help us understand what we could have done to make
        your experience better.
      </p>
      <div style={iframeContainer}>
        <DeleteForm userDetails={userDetails} onReady={memoizedUpdateCount} />
      </div>
      <div className="margin-top-5"></div>
      <LoadingButton
        loading={loading || disableButton}
        onClick={handleSubmit}
        loadingPosition="start"
        startIcon={<></>}
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          mt: 2,
          mb: 2,
          backgroundColor: disableButton
            ? "grey !important"
            : "#EB5757 !important",
        }}
      >
        Delete my account
      </LoadingButton>
    </>
  );
}
