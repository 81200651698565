import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoadingAnim from "../../../assets/lottie/loading_bubbles.json";
import WarningAnim from "../../../assets/lottie/404.json";
import PaymentSucess from "../../../assets/lottie/payment_sucess.json";
import Paymentload from "../../../assets/lottie/payment_loading.json";
import Button from "@mui/material/Button";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import "./p_state.css";

export function LoadingComponent({ label }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100 pt-5">
      <Lottie animationData={LoadingAnim} loop={true} />
      <p className="text-center" style={{ color: "#96b4cc" }}>
        {label}
      </p>
    </div>
  );
}

export function AythenticationFailed({ label }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <Lottie
        animationData={WarningAnim}
        loop={false}
        style={{ height: "200px" }}
      />
      <p className="p-2 text-center error_text">{label}</p>
      <Button
        variant="outlined"
        onClick={() => window.open("https://support.superr.app/en/", "_blank")}
        startIcon={<SupportAgentIcon fontSize="large" className="svgColor" />}
      >
        Contact Support
      </Button>
    </div>
  );
}

export function PaymentProcessing({ label }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <Lottie
        animationData={Paymentload}
        loop={true}
        style={{ height: "300px" }}
      />
      <p className="p-2 text-center pb-5">{label}</p>
    </div>
  );
}

export function PaymentSuccess({ label }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 pb-5">
      <Lottie
        animationData={PaymentSucess}
        loop={false}
        style={{ height: "300px" }}
      />
      <p className="p-2 text-center pb-5">{label}</p>
    </div>
  );
}
