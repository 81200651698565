import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useCookies } from "react-cookie";

import ImageSlider from "./components/image_slider";
import LoginForm from "./components/login-form";
import { LoadingComponent } from "../payment/components/p_states";
import { getAuthTokenApi } from "../../api/authentication-api";
import { useNavigate } from "react-router-dom";
import Intercom from "@intercom/messenger-js-sdk";

function Authentication() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user_token"]);
  const [autoAuthLoading, setAutoAuthLoading] = useState(false);

  let url = window.location;
  let search = window.location.search;
  let params = new URLSearchParams(search);

  useEffect(() => {
    let key = params.get("key");
    console.log("selfAuth", key);
    if (key !== null && key !== undefined) {
      setAutoAuthLoading(true);
      getTokenFromKey();
    }
  }, []);

  const getTokenFromKey = async () => {
    try {
      const authLink = url;
      // url.href;
      const res = await getAuthTokenApi(authLink.href);
      if (res.data.success) {
        console.log("selfAuth", res);
        Intercom("boot", {
          app_id: "dasnn3e1",
          user_id: res.data.data.guardianId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
          name: res.data.data.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
          email: res.data.data.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        });
        setCookie(
          "user_token",
          res.data.data.accessToken ?? res.data.data.token,
          {
            path: "/", // Ensure the path is set if needed
            expires: new Date(Date.now() + 86400e3), // 24 hours from now
          }
        );
        //console.log(response.data.data.token);
        navigate("/plans?isChoosePlan=false");
      } else {
        setAutoAuthLoading(false);
      }
    } catch (error) {
      console.log("selfAuth", error);
      setAutoAuthLoading(false);
    }
  };
  //console.log(autoAuthLoading);
  return (
    <div>
      {autoAuthLoading === true ? (
        <div style={{ height: "100vh" }}>
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Box
            sx={{
              position: { xs: "relative", md: "absolute" },
              top: { xs: "", md: "16px" },
              right: { xs: "", md: "16px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              pt: 2,
              pl: 2,
              pr: 2,
            }}
          >
            <img src="/images/full-logo.svg" alt="logo" height={30} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
              p: 2,
            }}
          >
            <Box
              sx={{
                flex: 1,
                width: "100%",
                height: "95vh",
                background: "linear-gradient(to right, #e0eafc, #cfdef3)",
                borderRadius: "16px",
              }}
            >
              <ImageSlider />
            </Box>
            <Box
              sx={{
                flex: 1,
                p: { xs: 1, md: 4 },
                pt: { xs: 3, md: 0 },
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "start", md: "center" },
                alignItems: "center",
              }}
            >
              {autoAuthLoading === true ? <LoadingComponent /> : <LoginForm />}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}

export default Authentication;

// https://auth.superr.app/payments?key=cce8af41b6bcf6a87d2d98e2476d08fed2423a10&env=ALPHA%22
