import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Snackbar from "awesome-snackbar";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { getPaymentPlanUrl } from "../../../../api/subscription-api";
import "./plan-selection.scss";

const OfferSelectionFooter = ({
  offerPlans,
  onSeeAllPlans,
  selectedOfferPlan,
}) => {
  const [getLinkApiLoading, setGetLinkApiLoading] = useState(false);
  const [cookies, setCookie] = useCookies([
    "user_token",
    "payemt_link_id",
    "server_env",
  ]);
  console.log("OfferSelectionFooter", offerPlans, selectedOfferPlan);
  const handleGetPaymentLink = async () => {
    setGetLinkApiLoading(true);
    try {
      const response = await getPaymentPlanUrl(
        cookies.user_token,
        selectedOfferPlan.plans[0].id
      );
      setCookie("payemt_link_id", response.data.linkId, {
        expires: new Date(Date.now() + 30 * 60000), // 30 minutes
      });
      setGetLinkApiLoading(false);
      window.open(response.data.link, "_self");
    } catch (err) {
      setGetLinkApiLoading(false);
      new Snackbar("Error generating payment link. Try Again!", {
        position: "top-center",
        timeout: 600,
        style: {
          container: [["background-color", "#E53935"]],
        },
      });
    }
  };
  const plan = selectedOfferPlan.plans[0];
  return (
    <Box
      className="plan-duration-selection"
      sx={{
        width: "100%",
        p: 2,
        pt: 3,
      }}
    >
      <Button
        fullWidth
        onClick={() => handleGetPaymentLink()}
        sx={{ mb: 1 }}
        className="primary-button"
        disabled={getLinkApiLoading}
      >
        {getLinkApiLoading ? (
          <CircularProgress size={24} sx={{ color: "blue" }} />
        ) : (
          `Claim Offer for ${plan.currency}${plan.price}`
        )}
      </Button>
      <Button
        fullWidth
        onClick={() => {
          onSeeAllPlans();
        }}
        className="secondary-button"
      >
        See all plans
      </Button>
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        <a href="https://superr-legal.s3.ap-south-1.amazonaws.com/EULA.html">
          Terms (EULA)
        </a>
        <a href="https://app.superr.app/terms-and-conditions">
          Terms & Conditions
        </a>
        <a href="https://superr-legal.s3.ap-south-1.amazonaws.com/privacy-policy.html">
          Privacy Policy
        </a>
      </Typography>
    </Box>
  );
};

export default OfferSelectionFooter;
