const sortSubscriptionPlansByDuration = (plans) => {
  plans.sort((a, b) => parseInt(b.data.units) - parseInt(a.data.units));
};
const getMonthlyPrice = (units, price, currency) => {
  // Convert days to months, rounding to the nearest month
  const months = Math.round(units / 30.44); // Average days in a month

  // Calculate the monthly price
  const monthlyPrice = Math.floor(price / months);
  console.log("getMonthlyPrice", months, units, price, monthlyPrice);
  const Component = () => {
    return (
      <>
        <b>
          {currency ?? "₹"}
          {monthlyPrice}
        </b>{" "}
        /month
      </>
    );
  };
  return <Component />;
};

function calculateDifference(baseDays, updatedDays, shorthand = false) {
  // Convert inputs to numbers
  baseDays = parseInt(baseDays, 10);
  updatedDays = parseInt(updatedDays, 10);

  // Check if inputs are valid numbers
  if (isNaN(baseDays) || isNaN(updatedDays)) {
    return "Invalid input. Please provide valid numbers.";
  }

  // Calculate the absolute difference in days
  const differenceInDays = Math.abs(updatedDays - baseDays);

  // Define conversion factors
  const daysInYear = 365;
  const daysInMonth = 30;

  // Determine the unit and value based on the difference
  let result;
  if (differenceInDays >= daysInYear) {
    const years = Math.floor(differenceInDays / daysInYear);
    result = shorthand ? `${years}yr` : `${years} year${years > 1 ? "s" : ""}`;
  } else if (differenceInDays >= daysInMonth) {
    const months = Math.floor(differenceInDays / daysInMonth);
    result = shorthand
      ? `${months}mon`
      : `${months} month${months > 1 ? "s" : ""}`;
  } else {
    result = shorthand
      ? `${differenceInDays} d`
      : `${differenceInDays} day${differenceInDays > 1 ? "s" : ""}`;
  }

  return result;
}

const getPriceWithDuration = (units, price, disPrice, currency, showPrice) => {
  const duration =
    units === 30
      ? showPrice
        ? "1 month"
        : "1mon"
      : units === 90
      ? showPrice
        ? "3 months"
        : "3mon"
      : units === 365
      ? showPrice
        ? "1 year"
        : "1yr"
      : units === 547
      ? showPrice
        ? "1yr + 6mon"
        : "1yr 6mon"
      : `${units} days`;
  const Component = () => {
    return (
      <>
        {currency ?? "₹"}
        {disPrice != null && disPrice !== price ? (
          <>
            <span className={"striked-text"}>{price}</span> {disPrice}
          </>
        ) : (
          <>{price}</>
        )}{" "}
        for {duration}
      </>
    );
  };
  return units <= 30 && !showPrice ? null : <Component />;
};

const getButtonLabelForPrice = (planActive, plan, currency) => {
  const subPrice = getPriceWithDuration(
    parseInt(plan.units),
    plan.discountedPrice ?? plan.price,
    null,
    currency,
    true
  );
  if (planActive) {
    return {
      label: <>Change to {subPrice}</>,
      disable: false,
    };
  }
  return {
    label: <>Subscribe for {subPrice}</>,
    disable: false,
  };
};

const createSubscriptionTierDetails = (activePlan, tier, availablePlans) => {
  const plans = [];
  let isActiveTier = false;

  for (const plan of tier.plans) {
    console.log(plan);
    const isCurrentlyActive =
      activePlan?.planId === plan.id &&
      activePlan?.recurringPaymentStatus === "active";
    // console.log(
    //   "isActive",
    //   activePlan,
    //   activePlan?.planId === plan.id,
    //   activePlan?.planId,
    //   plan.id
    // );
    if (isCurrentlyActive) {
      isActiveTier = true;
    }
    // console.log("getPriceWithDuration", plan);
    plans.push({
      tierIdentifier: tier.identifier,
      isActive: isCurrentlyActive,
      features: tier.features,
      ui: {
        header: `${plan.name}${isCurrentlyActive ? " (Active)" : ""}`,
        showPrice: getMonthlyPrice(
          parseInt(plan.units),
          plan.price,
          plan.currency
        ),
        paymentPrice: getPriceWithDuration(
          parseInt(plan.units),
          plan.price,
          plan.discountedPrice,
          plan.currency,
          false
        ),
        discount:
          plan.discountedPrice && plan.couponDetails?.discountType === "price"
            ? `${plan.discountPercentage}% off`
            : plan.couponDetails?.discountType === "unit"
            ? `+${calculateDifference(plan.baseUnits, plan.units, true)} Free`
            : null,
        discountCardText:
          plan.couponDetails?.discountType === "unit"
            ? `✨ Enjoy ${calculateDifference(
                plan.baseUnits,
                plan.units,
                false
              )} free with our yearly plan! `
            : null,
        buttonLabel: getButtonLabelForPrice(
          isCurrentlyActive,
          plan,
          plan.currency
        ).label,
      },
      data: plan,
    });
  }
  sortSubscriptionPlansByDuration(plans);
  //console.log(plans);
  return {
    displayName: tier.displayName,
    identifier: tier.identifier,
    isActive: isActiveTier,
    plans,
  };
};

const styles = ["base-style", "mid-style", "pro-style"];

const getPaywallData = (planDetails, active) => {
  const data = [];

  if (planDetails.subscriptionTiers != null) {
    planDetails.subscriptionTiers.forEach((tier, index) => {
      const style = styles[index % styles.length]; // Loop back to the beginning of the styles array if the index exceeds the length of the array
      const tierDetails = createSubscriptionTierDetails(
        active,
        tier,
        planDetails.subscriptionTiers
      );
      data.push({ ...tierDetails, style: style, features: tier.features });
    });
  }

  return data;
};

export const getPlansUi = (planDetails, active) => {
  try {
    // console.log("getPlansUi", planDetails, active);
    const paywallData = getPaywallData(planDetails, active);
    let selectedPlan;

    if (paywallData.some((tier) => tier.plans.some((plan) => plan.isActive))) {
      selectedPlan = paywallData
        .find((tier) => tier.isActive)
        .plans.find((plan) => plan.isActive);
    } else if (paywallData != null && paywallData.length > 0) {
      // console.log(paywallData);
      selectedPlan = paywallData[paywallData.length - 1].plans[0];
    }
    // Emit or update the state with the new data
    return {
      paywallData: paywallData,
      selectedPlan: selectedPlan,
      featuresTable: planDetails.featuredFeatures,
    };
  } catch (e) {
    console.error(e);
  }
};
