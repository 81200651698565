import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

// Define custom styles for the button
const useStyles = makeStyles({
  button: {
    borderRadius: "100px !important", // Adjust the border radius as needed
    color: "#FFFFFF",
    padding: "6px 24px", // Padding can be adjusted as needed
    margin: "8px",
    textTransform: "none", // Avoid uppercasing the button text
    fontSize: "14px !important", // Adjust the font size as needed
    fontWeight: "500",
  },
});

const RoundedDialogActionButton = ({
  label,
  onClick,
  color = "primary", // Default color
  icon = null, // Optional icon, can be a Material-UI Icon component
  style,
}) => {
  const classes = useStyles();
  const colorMap = {
    primary: "#17437D",
    error: "#D54553",
    default: "#F2F2F7",
  };

  return (
    <Button
      onClick={onClick}
      className={classes.button}
      style={{
        backgroundColor: colorMap[color] || colorMap.default,
        color: color === "default" ? "#000000" : "#FFFFFF", // Adjust text color for default background
        ...style,
      }}
      endIcon={icon}
    >
      {label}
    </Button>
  );
};

export default RoundedDialogActionButton;
